"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const React = require("react");
const webjs_sdk_1 = require("@aria/webjs-sdk");
const ErrorBoundary_1 = require("./components/common/ErrorBoundary");
const RootView_1 = require("./components/join-launcher/RootView");
const CoreContext_1 = require("./contexts/CoreContext");
const UserContext_1 = require("./contexts/UserContext");
const Environment_1 = require("./models/Environment");
const JoinInfo_1 = require("./models/JoinInfo");
const BootstrapService_1 = require("./services/BootstrapService");
const EnvironmentService_1 = require("./services/EnvironmentService");
const FeaturesService_1 = require("./services/FeaturesService");
const LauncherService_1 = require("./services/LauncherService");
const TranslationService_1 = require("./services/TranslationService");
const EcsClient_1 = require("./services/EcsClient");
const Logger_1 = require("./services/Logger");
const TelemetryEvents_1 = require("./models/telemetry/TelemetryEvents");
const BrandingService_1 = require("./services/BrandingService");
const Utils_1 = require("./helpers/Utils");
const LogsCollectorService_1 = require("./services/LogsCollectorService");
const PrivacyStatementHelper_1 = require("./helpers/PrivacyStatementHelper");
const UrlHelper_1 = require("./helpers/UrlHelper");
class AppBootstrapper {
    constructor() {
        this.bootstrap = () => EnvironmentService_1.default.init().then(info => {
            this.environment = info;
            Logger_1.default.setEnvironmentContext(info);
            return this.fetchFeatureFlags().then(flags => {
                this.flags = flags;
                this.bootstrapService = new BootstrapService_1.BootstrapService(this.environment, this.flags);
                this.joinInfo = this.bootstrapService.getJoinInfo();
                this.userContext = this.bootstrapService.userInfo;
                this.launcherService = new LauncherService_1.LauncherService(this.environment, this.flags, this.joinInfo);
                this.brandingService = new BrandingService_1.BrandingService(document, this.flags.brandingFetchTimeout, this.flags.brandingWithImages, this.flags.ignoreBrandingTimeout);
                const meetingTypes = [JoinInfo_1.JoinType.Meetup, JoinInfo_1.JoinType.MeetupWithCode];
                this.isMeetupLink =
                    meetingTypes.indexOf(this.joinInfo.type) > -1 &&
                        !(this.joinInfo.meetingInfo && this.joinInfo.meetingInfo.isBroadcast);
                const isFremiumMeetingsLink = this.bootstrapService.type === JoinInfo_1.JoinType.FremiumMeeting;
                const isShareEmailMeetingsLink = this.bootstrapService.type === JoinInfo_1.JoinType.ShareEmail;
                this.isMobileModernView =
                    (this.isMeetupLink || this.joinInfo.isTflLink) &&
                        this.environment.deviceType !== Environment_1.DeviceTypes.Desktop;
                this.isWorkModernView =
                    (this.isMeetupLink || isFremiumMeetingsLink || isShareEmailMeetingsLink) &&
                        this.environment.deviceType === Environment_1.DeviceTypes.Desktop;
                // Modern Life Experience -> TFL for meetings, invites, claim, teamsInsider, schoolConnection, skype
                // Communities (for Win11 or based on T1 support)
                // Message links on TFL are for now considered Community posts as there is no way for sharing message link from other chats
                this.isLifeModernView =
                    (this.bootstrapService.type === JoinInfo_1.JoinType.SchoolConnection &&
                        this.flags.enableSchoolConnection) ||
                        this.joinInfo.isTflSkypeRelatedJoin ||
                        this.bootstrapService.type === JoinInfo_1.JoinType.TeamsInsider ||
                        this.bootstrapService.type === JoinInfo_1.JoinType.TflCommunity ||
                        (this.environment.isTflEnvironment &&
                            (this.isMeetupLink ||
                                this.bootstrapService.type === JoinInfo_1.JoinType.TflInvite ||
                                this.bootstrapService.type === JoinInfo_1.JoinType.TflClaim ||
                                this.bootstrapService.type === JoinInfo_1.JoinType.Message));
                if (this.isMobileModernView || this.isLifeModernView || this.isWorkModernView) {
                    if (!this.isMobileModernView || !this.flags.enableMobilePage) {
                        this.setupV3UI();
                        this.setupBranding();
                    }
                    else {
                        this.setupV2UI();
                    }
                }
                // Load privacy statement which depends on the current tenant.
                this.fetchPrivacyStatementLink();
                LogsCollectorService_1.default.logBootstrapFields(this.environment, this.userContext, this.joinInfo, this.flags);
                const { rootViewCustomClass, containerCustomClass } = this.calculateCustomContainerStyling();
                this.computedFlags = {
                    rootViewCustomClass,
                    containerCustomClass,
                };
                // The render method accepts an argument to determine if modernViewContainer should render with the shimmer effect or not
                // This is required because shimmer and branding should only be rendered for meetup-join
                return Promise.resolve(this.render());
            });
        });
        this.cleanup = () => {
            this.brandingService.cleanup();
        };
        this.render = () => {
            const language = this.getLanguage();
            const languageBundle = I18NBUNDLE;
            TranslationService_1.default.init(languageBundle, language);
            const isModernView = this.isMobileModernView || this.isLifeModernView || this.isWorkModernView;
            const modernViewType = this.getModernViewType();
            return (React.createElement(CoreContext_1.CoreContextProvider, { value: {
                    environment: this.environment,
                    featureFlags: this.flags,
                    language,
                    languageBundle,
                    isModernView,
                    modernViewType,
                    launcherService: this.launcherService,
                    brandingService: this.brandingService,
                    isMobileModernView: this.isMobileModernView,
                } },
                React.createElement(ErrorBoundary_1.ErrorBoundary, null,
                    React.createElement(UserContext_1.UserContextProvider, { value: this.userContext },
                        React.createElement(RootView_1.RootView, { environment: this.environment, launcherService: this.launcherService, featureFlags: this.flags, joinInfo: this.joinInfo, computedFlags: this.computedFlags })))));
        };
        this.getLanguage = () => document.getElementsByTagName("html")[0].getAttribute("lang") || "en-us";
    }
    fetchFeatureFlags() {
        const flags = FeaturesService_1.default.getFeatureFlags();
        const isUserEngagedBefore = !!localStorage.getItem("lastTimeUserEngagement");
        const isChrome = this.environment.browserName === Environment_1.Browsers.Chrome;
        const isTFW = !this.environment.isTflEnvironment;
        const isDesktop = this.environment.deviceType === Environment_1.DeviceTypes.Desktop;
        if (flags.enableExperiment && !isUserEngagedBefore && isChrome && isTFW && isDesktop) {
            const ecsFilter = this.buildEcsFilter();
            return EcsClient_1.default
                .getFlags(ecsFilter)
                .then(ecsResponse => {
                Logger_1.default.setExperimentContext(ecsResponse.configIDs, ecsResponse.flags);
                LogsCollectorService_1.default.logEcsResponse(ecsResponse);
                FeaturesService_1.default.setConfigIDs(ecsResponse.configIDs);
                return FeaturesService_1.default.updateFlagsWithEcs(ecsResponse.flags);
            })
                .catch(e => {
                const error = new TelemetryEvents_1.GenericErrorEvent({
                    message: "Failed to set ECS flags",
                    error: Utils_1.Utils.scrubEuii(e.message),
                });
                Logger_1.default.log(error, webjs_sdk_1.AWTEventPriority.Immediate_sync);
                return flags;
            });
        }
        return Promise.resolve(flags);
    }
    buildEcsFilter() {
        return {
            BrowserName: this.environment.browserName,
            TeamsRing: this.environment.ring,
            clientId: this.environment.deviceId,
        };
    }
    setupV2UI() {
        const className = " uiv2";
        const htmlElm = self.document.getElementsByTagName("html")[0];
        if (htmlElm) {
            htmlElm.className += className;
        }
        if (this.environment.browserName === Environment_1.Browsers.Edge) {
            htmlElm.className += " edge";
        }
    }
    setupV3UI() {
        const className = " uiv3";
        const htmlElm = self.document.getElementsByTagName("html")[0];
        if (htmlElm) {
            htmlElm.className += className;
        }
    }
    setupBranding() {
        var _a, _b;
        if (this.shouldStartBranding()) {
            let brandingFetchParameters;
            switch (this.joinInfo.type) {
                case JoinInfo_1.JoinType.Meetup:
                    brandingFetchParameters = {
                        kind: this.joinInfo.type,
                        tenantId: this.joinInfo.meetingInfo.tenantId,
                        organizerId: this.joinInfo.meetingInfo.organizerId,
                        threadId: this.flags.shouldGetAppliedMeetingBrandingTheme
                            ? (_a = this.joinInfo) === null || _a === void 0 ? void 0 : _a.threadId
                            : null,
                        messageId: this.flags.shouldGetAppliedMeetingBrandingTheme
                            ? (_b = this.joinInfo) === null || _b === void 0 ? void 0 : _b.messageId
                            : null,
                    };
                    break;
                case JoinInfo_1.JoinType.MeetupWithCode:
                    brandingFetchParameters = {
                        kind: this.joinInfo.type,
                        meetingId: this.joinInfo.code,
                        passcode: this.joinInfo.passcode,
                        getAppliedMeetingBrandingTheme: this.flags.shouldGetAppliedMeetingBrandingTheme,
                    };
                    break;
                default:
                    const joinInfoString = JSON.stringify(this.joinInfo);
                    const scrubbedJoinInfo = Utils_1.Utils.scrubEuii(Utils_1.Utils.scrubPasscode(joinInfoString));
                    const errorMessage = `Invalid join type. Join Info: ${scrubbedJoinInfo}`;
                    const error = new TelemetryEvents_1.GenericErrorEvent({
                        message: errorMessage,
                        error: errorMessage,
                    });
                    Logger_1.default.log(error, webjs_sdk_1.AWTEventPriority.Immediate_sync);
            }
            if (!!brandingFetchParameters) {
                this.brandingService.startBranding(brandingFetchParameters);
            }
        }
    }
    fetchPrivacyStatementLink() {
        var _a, _b, _c, _d, _e, _f, _g;
        const enableOrganizationPrivacyStatement = (_a = this.flags) === null || _a === void 0 ? void 0 : _a.enableOrganizationPrivacyStatement;
        const isTfl = (_b = this.environment) === null || _b === void 0 ? void 0 : _b.isTflEnvironment;
        if (!enableOrganizationPrivacyStatement || !this.isMeetupLink || isTfl) {
            return;
        }
        let joinUrl = `${UrlHelper_1.UrlHelper.getOrigin()}${(_c = this.joinInfo) === null || _c === void 0 ? void 0 : _c.deepLinkUrl}`;
        let privacyStatementInput = { joinUrl };
        switch (this.joinInfo.type) {
            case JoinInfo_1.JoinType.Meetup:
                const tenantId = (_e = (_d = this.joinInfo) === null || _d === void 0 ? void 0 : _d.meetingInfo) === null || _e === void 0 ? void 0 : _e.tenantId;
                const organizerId = (_g = (_f = this.joinInfo) === null || _f === void 0 ? void 0 : _f.meetingInfo) === null || _g === void 0 ? void 0 : _g.organizerId;
                const contextParam = UrlHelper_1.UrlHelper.getQueryParameter("context", joinUrl);
                joinUrl = UrlHelper_1.UrlHelper.addQueryParameter(UrlHelper_1.UrlHelper.stripParams(joinUrl), "context", contextParam, true);
                privacyStatementInput = {
                    joinUrl,
                    tenantId,
                    organizerId,
                };
                (0, PrivacyStatementHelper_1.fetchAndApplyPrivacyStatement)(privacyStatementInput);
                break;
            case JoinInfo_1.JoinType.MeetupWithCode:
                privacyStatementInput = {
                    joinUrl,
                    meetingCode: this.joinInfo.code,
                };
                (0, PrivacyStatementHelper_1.fetchAndApplyPrivacyStatement)(privacyStatementInput);
                break;
        }
    }
    getModernViewType() {
        // TFL Modern view has priority over others once flagged
        // there may be overlap with them so order is important at this time
        if (this.isLifeModernView) {
            return RootView_1.ModernViewType.TflModernView;
        }
        else {
            if (this.isMobileModernView) {
                return RootView_1.ModernViewType.MobileMeeting;
            }
            if (this.environment.deviceType === Environment_1.DeviceTypes.Desktop) {
                if (this.isMeetupLink) {
                    return RootView_1.ModernViewType.Meeting;
                }
                if (this.bootstrapService.type === JoinInfo_1.JoinType.FremiumMeeting) {
                    return RootView_1.ModernViewType.FreemiumOnboarding;
                }
                if (this.bootstrapService.type === JoinInfo_1.JoinType.ShareEmail) {
                    return RootView_1.ModernViewType.ShareEmail;
                }
            }
        }
        return RootView_1.ModernViewType.None;
    }
    shouldStartBranding() {
        const modernBrowser = "fetch" in window && "assign" in Object && "Set" in window;
        const isValidMeetupWithCodeJoin = this.flags.enableBrandingFetchWithMeetingId &&
            this.joinInfo.type === JoinInfo_1.JoinType.MeetupWithCode &&
            !!this.joinInfo.code;
        const isValidMeetupJoin = this.joinInfo.type === JoinInfo_1.JoinType.Meetup && !!this.joinInfo.meetingInfo;
        return (this.flags.enableBranding && modernBrowser && (isValidMeetupWithCodeJoin || isValidMeetupJoin));
    }
    calculateCustomContainerStyling() {
        const showHeaderContainerShimmer = this.shouldStartBranding();
        let containerCustomClass = !showHeaderContainerShimmer ? "noShimmer" : "";
        let rootViewCustomClass = "";
        if (this.joinInfo.isTflSkypeRelatedJoin) {
            rootViewCustomClass = this.joinInfo.isSkypeSupported ? "skypeSupported" : "skypeUnsupported";
        }
        containerCustomClass = "";
        return { rootViewCustomClass, containerCustomClass };
    }
}
exports.default = AppBootstrapper;
