"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ModernViewRenderer = void 0;
const React = require("react");
const CoreContext_1 = require("../../contexts/CoreContext");
const JoinLauncherContext_1 = require("../../contexts/join-launcher/JoinLauncherContext");
const ButtonsFactory_1 = require("../../helpers/ButtonsFactory");
const Environment_1 = require("../../models/Environment");
const JoinInfo_1 = require("../../models/JoinInfo");
const TelemetryEvents_1 = require("../../models/telemetry/TelemetryEvents");
const LauncherService_1 = require("../../services/LauncherService");
const Logger_1 = require("../../services/Logger");
const Launching_1 = require("../common/modern/Launching");
const ModernSimplifiedView_1 = require("./ModernSimplifiedView");
const ModernLifeSimplifiedView_1 = require("./ModernLifeSimplifiedView");
const RootView_1 = require("./RootView");
const CommunityEventsHelper_1 = require("../../helpers/CommunityEventsHelper");
class ModernViewRenderer extends React.Component {
    constructor(props) {
        super(props);
        this.finalPanelViewReported = false;
        this.loadingPanelViewReported = false;
    }
    render() {
        return (React.createElement(CoreContext_1.CoreContextConsumer, null, ({ environment }) => (React.createElement(JoinLauncherContext_1.JoinLauncherContextConsumer, null, ({ joinInfo }) => this.renderContent(environment, joinInfo)))));
    }
    shouldShowIeEdgeLaunchedFailed() {
        return (this.props.launchResult === LauncherService_1.LaunchResults.UserCancelled ||
            this.props.launchResult === LauncherService_1.LaunchResults.NoHandler ||
            this.props.launchResult === LauncherService_1.LaunchResults.NotAttempted);
    }
    shouldShowIeEdgeLauncherSucceeded() {
        return (this.props.launchResult === LauncherService_1.LaunchResults.Success ||
            this.props.launchResult === LauncherService_1.LaunchResults.PreviouslySucceeded);
    }
    renderContent(environment, joinInfo) {
        const { buttonsFactory } = this.props;
        // Linux client is deprecated, so there are no join in app or download buttons. If Web is not supported either,
        // jump straight to not supported page
        if (environment.canonicalOs === Environment_1.CanonicalOperatingSystems.Linux &&
            environment.deviceType === Environment_1.DeviceTypes.Desktop &&
            !environment.isWebClientSupportedBrowser) {
            return this.renderNotSupportedPage();
        }
        // TFL - only T21 supports Skype Interop, jump straight to not supported page if web is not supported either
        if (environment.isTflEnvironment &&
            joinInfo.isTflSkypeRelatedJoin &&
            environment.deviceType === Environment_1.DeviceTypes.Desktop &&
            !environment.isWebClientSupportedBrowser &&
            !environment.isT21SupportedOS) {
            return this.renderNotSupportedPage();
        }
        switch (environment.browserName) {
            case Environment_1.Browsers.Edge: {
                const instructionsPanelTitle = this.getInstructionsPanelTitle(environment.browserName, joinInfo, environment);
                const instructionsPanelText = this.getInstructionsPanelText(environment);
                if (this.shouldShowIeEdgeLauncherSucceeded()) {
                    return this.renderSelection(buttonsFactory.getEdgeSuccessButtons(), instructionsPanelTitle, instructionsPanelText);
                }
                else if (this.shouldShowIeEdgeLaunchedFailed()) {
                    return this.renderSelection(buttonsFactory.getEdgeFailureButtons(), instructionsPanelTitle, instructionsPanelText);
                }
                else {
                    return this.renderLoadingPage();
                }
            }
            case Environment_1.Browsers.Chrome:
            case Environment_1.Browsers.EdgeChromium:
                if (environment.isWebClientSupportedBrowser && environment.isNativeClientSupportedOS) {
                    const instructionsPanelTitle = this.getInstructionsPanelTitle(environment.browserName, joinInfo, environment);
                    const instructionsPanelText = this.getInstructionsPanelText(environment);
                    let buttons;
                    if (environment.canonicalOs === Environment_1.CanonicalOperatingSystems.Mac) {
                        buttons = buttonsFactory.getChromeMacOsButtons();
                    }
                    else if (environment.canonicalOs === Environment_1.CanonicalOperatingSystems.Win) {
                        buttons = buttonsFactory.getChromeWindowsButtons();
                    }
                    else {
                        buttons = buttonsFactory.getChromeLinuxButtons();
                    }
                    return this.renderSelection(buttons, instructionsPanelTitle, instructionsPanelText);
                }
                else if (!environment.isWebClientSupportedBrowser) {
                    return this.renderUnsupportedBrowsers(buttonsFactory, environment, joinInfo);
                }
                else {
                    // no download, go directly to web
                    return this.renderSelection(buttonsFactory.getChromeUnsupportedOsButtons(), "hdr_generic_title");
                }
            case Environment_1.Browsers.Safari:
                if (environment.isWebClientSupportedBrowser && environment.isNativeClientSupportedOS) {
                    const instructionsPanelTitle = this.getInstructionsPanelTitle(environment.browserName, joinInfo, environment);
                    const instructionsPanelText = this.getInstructionsPanelText(environment);
                    return this.renderSelection(buttonsFactory.getSafariMacOsButtons(), instructionsPanelTitle, instructionsPanelText);
                }
                else {
                    return this.renderUnsupportedBrowsers(buttonsFactory, environment, joinInfo);
                }
            case Environment_1.Browsers.Firefox:
                if (environment.isWebClientSupportedBrowser && environment.isNativeClientSupportedOS) {
                    const instructionsPanelTitle = this.getInstructionsPanelTitle(environment.browserName, joinInfo, environment);
                    const instructionsPanelText = this.getInstructionsPanelText(environment);
                    let buttons;
                    if (environment.canonicalOs === Environment_1.CanonicalOperatingSystems.Mac) {
                        buttons = buttonsFactory.getFirefoxMacOsButtons();
                    }
                    else if (environment.canonicalOs === Environment_1.CanonicalOperatingSystems.Win) {
                        buttons = buttonsFactory.getFirefoxWindowsButtons();
                    }
                    else {
                        buttons = buttonsFactory.getFirefoxLinuxButtons();
                    }
                    return this.renderSelection(buttons, instructionsPanelTitle, instructionsPanelText);
                }
                else {
                    return this.renderUnsupportedBrowsers(buttonsFactory, environment, joinInfo);
                }
            // FF and other browsers
            default:
                return this.renderUnsupportedBrowsers(buttonsFactory, environment, joinInfo);
        }
    }
    getInstructionsPanelTitle(browserName, joinInfo, environment) {
        const joinType = joinInfo.type;
        if (browserName === Environment_1.Browsers.Edge) {
            if (this.shouldShowIeEdgeLauncherSucceeded()) {
                if (joinType === JoinInfo_1.JoinType.ShareEmail) {
                    return "hdr_share_email_launcher_success";
                }
                return "hdr_generic_title";
            }
        }
        if (joinType === JoinInfo_1.JoinType.ShareEmail) {
            return "hdr_share_email";
        }
        if (joinType === JoinInfo_1.JoinType.TflCommunity ||
            (joinType === JoinInfo_1.JoinType.Message && environment.isTflEnvironment)) {
            return "hdr_mobile_community_title";
        }
        else if (joinType === JoinInfo_1.JoinType.TflInvite || joinType === JoinInfo_1.JoinType.TflClaim) {
            return "hdr_mobile_first_conversation";
        }
        else if (joinType === JoinInfo_1.JoinType.TeamsInsider) {
            return "hdr_teams_insider_title";
        }
        else if (joinType === JoinInfo_1.JoinType.SchoolConnection) {
            return "hdr_school_connection_title";
        }
        else if (environment.isTflEnvironment && CommunityEventsHelper_1.CommunityEventsHelper.isCommunityEvent(joinInfo)) {
            return "hdr_community_event_title";
        }
        else if (joinInfo.isTflSkypeRelatedJoin) {
            return "hdr_teams_skype_title";
        }
        return "hdr_generic_title";
    }
    getInstructionsPanelText(environment) {
        if (environment.isWebClientSupportedBrowser) {
            return "";
        }
        // TFL has custom text that tells users to join on teams app
        return environment.isTflEnvironment
            ? "txt_browser_version_not_supported"
            : "txt_no_browser_support";
    }
    renderUnsupportedBrowsers(buttonsFactory, environment, joinInfo) {
        let instructionsPanelTitle;
        let instructionsPanelText;
        if (environment.isNativeClientSupportedOS) {
            instructionsPanelTitle = this.getInstructionsPanelTitle(environment.browserName, joinInfo, environment);
            instructionsPanelText = this.getInstructionsPanelText(environment);
            let buttons;
            if (environment.canonicalOs === Environment_1.CanonicalOperatingSystems.Mac) {
                buttons = buttonsFactory.getOtherBrowsersMacOsButtons();
            }
            else if (environment.canonicalOs === Environment_1.CanonicalOperatingSystems.Win) {
                buttons = buttonsFactory.getOtherBrowsersWindowsButtons();
            }
            else {
                buttons = buttonsFactory.getOtherBrowsersLinuxButtons();
            }
            return this.renderSelection(buttons, instructionsPanelTitle, instructionsPanelText);
        }
        else {
            return this.renderNotSupportedPage();
        }
    }
    // Reports panel view with shown buttons
    reportSuccessPanelView(name, joinInfo, props) {
        if (!this.finalPanelViewReported) {
            const panelViewEvent = new TelemetryEvents_1.PanelViewEvent(name, joinInfo, Object.assign(Object.assign({}, props), { launchResult: this.props.launchResult }));
            Logger_1.default.logUserBiPanelView(panelViewEvent);
            this.finalPanelViewReported = true;
        }
    }
    renderSelection(
    // TODO: Address button type when UIv2 is going to be deprecated
    buttons, instructionsPanelTitle, instructionsPanelText) {
        return buttons.length === 0 ? (this.renderNotSupportedPage()) : (React.createElement(JoinLauncherContext_1.JoinLauncherContextConsumer, null, ({ joinInfo }) => {
            const buttonNames = buttons.map(item => ButtonsFactory_1.ButtonKey[item.key]).toString();
            this.reportSuccessPanelView("join_launcher_loaded", joinInfo, {
                "DataBag.availableOptions": buttonNames,
            });
            return (React.createElement("div", { className: "modernViewRenderer" }, this.props.type === RootView_1.ModernViewType.TflModernView ? (React.createElement(ModernLifeSimplifiedView_1.ModernLifeSimplifiedView, { buttons: buttons, instructionsPanelText: instructionsPanelText, instructionsPanelTitle: instructionsPanelTitle })) : (React.createElement(ModernSimplifiedView_1.ModernSimplifiedView, { buttons: buttons, instructionsPanelText: instructionsPanelText, instructionsPanelTitle: instructionsPanelTitle }))));
        }));
    }
    renderNotSupportedPage() {
        return (React.createElement(JoinLauncherContext_1.JoinLauncherContextConsumer, null, ({ joinInfo }) => {
            if (!this.finalPanelViewReported) {
                const panelViewEvent = new TelemetryEvents_1.PanelViewEvent("join_launcher_unsupported", joinInfo);
                Logger_1.default.logUserBiPanelView(panelViewEvent);
                this.finalPanelViewReported = true;
            }
            let instructionsPanelTitle;
            let instructionsPanelText;
            switch (joinInfo.type) {
                case JoinInfo_1.JoinType.TeamsInsider:
                    instructionsPanelTitle = "hdr_teams_insider_raw_title";
                    instructionsPanelText = "hdr_teams_insider_unsupported_description";
                    break;
                case JoinInfo_1.JoinType.SchoolConnection:
                    instructionsPanelTitle = "hdr_school_connection_desktop_title";
                    instructionsPanelText = "hdr_school_connection_desktop_description";
                    break;
                default:
                    instructionsPanelTitle = "hdr_unsupported_browser_or_environment";
                    instructionsPanelText = "txt_unsupported_browser_or_environment";
            }
            return (React.createElement("div", null, this.props.type === RootView_1.ModernViewType.TflModernView ? (React.createElement(ModernLifeSimplifiedView_1.ModernLifeSimplifiedView, { instructionsPanelTitle: instructionsPanelTitle, instructionsPanelText: instructionsPanelText })) : (React.createElement(ModernSimplifiedView_1.ModernSimplifiedView, { instructionsPanelTitle: instructionsPanelTitle, instructionsPanelText: instructionsPanelText }))));
        }));
    }
    renderLoadingPage() {
        return (React.createElement(JoinLauncherContext_1.JoinLauncherContextConsumer, null, ({ joinInfo }) => {
            if (!this.loadingPanelViewReported) {
                const panelViewEvent = new TelemetryEvents_1.PanelViewEvent("join_launcher_loading", joinInfo);
                Logger_1.default.logUserBiPanelView(panelViewEvent);
                this.loadingPanelViewReported = true;
            }
            return React.createElement(Launching_1.Launching, { text: "hdr_launching_teams_app" });
        }));
    }
}
exports.ModernViewRenderer = ModernViewRenderer;
