"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PanelActionEvent = exports.PanelViewEvent = exports.PanelEvent = exports.QRCodeGenerationFailedEvent = exports.EcsClientErrorEvent = exports.EcsClientEvent = exports.PDSRedirectEvent = exports.UnloadLauncherEvent = exports.PrivacyStatementEvent = exports.BrandingEvent = exports.ScriptErrorEvent = exports.GenericErrorEvent = exports.LoadingLauncherCompleteEvent = exports.ParsingCompleteEvent = exports.ParsingFailedEvent = exports.InitialParsingFailedEvent = exports.LoadingLauncherErrorEvent = exports.LoadingLauncherStartEvent = exports.TryOpenAgainEvent = exports.AutoRedirectOpenTeamsEvent = exports.OpenTeamsEvent = exports.OpenDownloadPageLink = exports.OpenLearnMoreLink = exports.ImageLoadFailedEvent = exports.DownloadEvent = exports.ModuleNames = exports.PanelActionScenarioTypes = exports.PanelActionScenarioNames = exports.DownloadEventOrigins = exports.CommunityEventProps = exports.DataBagProps = exports.ProtocolLaunchEvent = exports.AJoinTelemetryEvent = void 0;
const JoinInfo_1 = require("../JoinInfo");
class AJoinTelemetryEvent {
    constructor(joinInfo) {
        this.joinInfo = joinInfo;
    }
    getProperties() {
        return {
            organizerId: this.joinInfo.meetingInfo && this.joinInfo.meetingInfo.organizerId,
            tenantId: this.joinInfo.meetingInfo && this.joinInfo.meetingInfo.tenantId,
            threadId: this.joinInfo.threadId,
            messageId: this.joinInfo.messageId,
            code: this.joinInfo.code,
            launchAgent: this.joinInfo.launchAgent,
            laEntry: this.joinInfo.laEntry,
            laExpId: this.joinInfo.laExpId,
            isMeetingCreation: this.joinInfo.isMeetingCreation,
            correlationId: this.joinInfo.correlationId,
            [DataBagProps.Source]: this.joinInfo.deepLinkSource,
            [DataBagProps.EventTypeQsp]: this.joinInfo.eventTypeQsp,
        };
    }
}
exports.AJoinTelemetryEvent = AJoinTelemetryEvent;
class ProtocolLaunchEvent {
    constructor(props) {
        this.props = props;
    }
    get name() {
        return "protocolLaunch";
    }
    get properties() {
        return this.props;
    }
}
exports.ProtocolLaunchEvent = ProtocolLaunchEvent;
var DataBagProps;
(function (DataBagProps) {
    DataBagProps["Source"] = "DataBag.source";
    DataBagProps["RequestContextProvided"] = "DataBag.requestContextProvided";
    // Value based on query string param eventType
    DataBagProps["EventTypeQsp"] = "DataBag.qspEventType";
    // Either direct skype deeplink or any other deeplink like chat/message coming from skype client
    DataBagProps["IsTflSkypeRelatedJoin"] = "DataBag.isTflSkypeRelatedJoin";
})(DataBagProps = exports.DataBagProps || (exports.DataBagProps = {}));
/**
 * CommunityEvent DataBag props
 * - values are based on server context
 */
var CommunityEventProps;
(function (CommunityEventProps) {
    CommunityEventProps["EventType"] = "CommunityEvent.eventType";
    CommunityEventProps["MeetingType"] = "CommunityEvent.meetingType";
    CommunityEventProps["EventId"] = "CommunityEvent.calendarEventId";
    CommunityEventProps["CommunityId"] = "CommunityEvent.communityId";
})(CommunityEventProps = exports.CommunityEventProps || (exports.CommunityEventProps = {}));
var DownloadEventOrigins;
(function (DownloadEventOrigins) {
    DownloadEventOrigins["DownloadButton"] = "launcher_download_click";
    DownloadEventOrigins["GoogleAppStoreButton"] = "google_play_store_btn_click";
    DownloadEventOrigins["AppleAppStoreButton"] = "apple_app_store_btn_click";
})(DownloadEventOrigins = exports.DownloadEventOrigins || (exports.DownloadEventOrigins = {}));
// ScenarioName should hold information about used view, link details are in deeplinkType
var PanelActionScenarioNames;
(function (PanelActionScenarioNames) {
    PanelActionScenarioNames["JoinView"] = "joinView";
    PanelActionScenarioNames["ModernMobileView"] = "modernMobileView";
    PanelActionScenarioNames["ModernSimplifiedMobileView"] = "modernSimplifiedMobileView";
    PanelActionScenarioNames["ModernView"] = "modernView";
    PanelActionScenarioNames["ContactSyncView"] = "contactSyncView";
    PanelActionScenarioNames["CommunityJoinView"] = "communityJoinView";
    PanelActionScenarioNames["GoogleSyncJoinView"] = "googleSyncJoinView";
})(PanelActionScenarioNames = exports.PanelActionScenarioNames || (exports.PanelActionScenarioNames = {}));
// Having just one major scenario type for whole JoinLauncher
// Use Scenario for used view details and deeplinkType for link specification
var PanelActionScenarioTypes;
(function (PanelActionScenarioTypes) {
    PanelActionScenarioTypes["JoinLauncher"] = "JoinLauncher";
})(PanelActionScenarioTypes = exports.PanelActionScenarioTypes || (exports.PanelActionScenarioTypes = {}));
var ModuleNames;
(function (ModuleNames) {
    ModuleNames["CopyLinkToClipboard"] = "copyLinkToClipboard";
    ModuleNames["Download"] = "download";
    ModuleNames["DownloadAppleStore"] = "downloadAppleStore";
    ModuleNames["DownloadGooglePlay"] = "downloadGooglePlay";
    ModuleNames["JoinOnWeb"] = "joinOnWeb";
    ModuleNames["JoinInApp"] = "joinInApp";
    ModuleNames["JoinInEdge"] = "joinInEdge";
    ModuleNames["RedirectToEdge"] = "redirectToEdge";
    ModuleNames["LearnMore"] = "learnMore";
    ModuleNames["ShowThirdPartyNotice"] = "showThirdPartyNotice";
    ModuleNames["TryEdgeAgain"] = "tryEdgeAgain";
    ModuleNames["ShowGoogleSyncType"] = "showGoogleSyncType";
    ModuleNames["AddToCalendar"] = "addToCalendar";
    ModuleNames["ViewDetails"] = "viewDetails";
})(ModuleNames = exports.ModuleNames || (exports.ModuleNames = {}));
class DownloadEvent extends AJoinTelemetryEvent {
    constructor(origin, props, joinInfo) {
        super(joinInfo);
        this.origin = origin;
        this.props = props;
    }
    get name() {
        return this.origin;
    }
    get properties() {
        return Object.assign(Object.assign({}, super.getProperties()), this.props);
    }
}
exports.DownloadEvent = DownloadEvent;
class ImageLoadFailedEvent extends AJoinTelemetryEvent {
    constructor(joinInfo, locale) {
        super(joinInfo);
        this.locale = locale;
    }
    get name() {
        return "image_load_failed";
    }
    get properties() {
        return Object.assign(Object.assign({}, super.getProperties()), { locale: this.locale });
    }
}
exports.ImageLoadFailedEvent = ImageLoadFailedEvent;
class OpenLearnMoreLink {
    get name() {
        return "learn_more_link_opened";
    }
    get properties() {
        return {};
    }
}
exports.OpenLearnMoreLink = OpenLearnMoreLink;
class OpenDownloadPageLink {
    get name() {
        return "download_page_link_opened";
    }
    get properties() {
        return {};
    }
}
exports.OpenDownloadPageLink = OpenDownloadPageLink;
class OpenTeamsEvent extends AJoinTelemetryEvent {
    constructor(joinInfo, props) {
        super(joinInfo);
        this.props = props;
    }
    get name() {
        return "launcher_open_teams_click";
    }
    get properties() {
        return Object.assign(Object.assign(Object.assign({}, super.getProperties()), this.props), { launchedAllowAnonymous: this.joinInfo.isAnonymousEnabled, launchedTenantId: this.joinInfo.guestAccessTenantId });
    }
}
exports.OpenTeamsEvent = OpenTeamsEvent;
class AutoRedirectOpenTeamsEvent extends AJoinTelemetryEvent {
    constructor(joinInfo, autoWebJoinReason, props) {
        super(joinInfo);
        this.autoWebJoinReason = autoWebJoinReason;
        this.props = props;
    }
    get name() {
        return "launcher_web_auto_redirect";
    }
    get properties() {
        return Object.assign(Object.assign(Object.assign({}, super.getProperties()), this.props), { launchedAllowAnonymous: this.joinInfo.isAnonymousEnabled, launchedTenantId: this.joinInfo.guestAccessTenantId, autoWebJoinReason: this.autoWebJoinReason });
    }
}
exports.AutoRedirectOpenTeamsEvent = AutoRedirectOpenTeamsEvent;
class TryOpenAgainEvent extends AJoinTelemetryEvent {
    constructor(joinInfo) {
        super(joinInfo);
    }
    get name() {
        return "launcher_try_again_click";
    }
    get properties() {
        return super.getProperties();
    }
}
exports.TryOpenAgainEvent = TryOpenAgainEvent;
class LoadingLauncherStartEvent {
    get name() {
        return "launcher_page_is_loading";
    }
    get properties() {
        return {};
    }
}
exports.LoadingLauncherStartEvent = LoadingLauncherStartEvent;
class LoadingLauncherErrorEvent {
    get name() {
        return "error_500_page_loaded";
    }
    get properties() {
        return {};
    }
}
exports.LoadingLauncherErrorEvent = LoadingLauncherErrorEvent;
class InitialParsingFailedEvent {
    constructor(props) {
        this.props = props;
    }
    get name() {
        return "launcher_url_params_initial_parsing_failed";
    }
    get properties() {
        return this.props;
    }
}
exports.InitialParsingFailedEvent = InitialParsingFailedEvent;
class ParsingFailedEvent {
    get name() {
        return "launcher_url_params_parsing_failed";
    }
    get properties() {
        return {};
    }
}
exports.ParsingFailedEvent = ParsingFailedEvent;
class ParsingCompleteEvent {
    constructor(props) {
        this.props = props;
    }
    get name() {
        return "launcher_url_params_parsed";
    }
    get properties() {
        const props = {
            threadId: this.props.threadId,
            code: this.props.code,
            meeting: "unknown",
            meetingType: "unknown",
        };
        if ([JoinInfo_1.JoinType.Meetup, JoinInfo_1.JoinType.Call].includes(this.props.type)) {
            const meetingType = this.props.isPrivate ? "private" : "channel";
            const meeting = this.props.type === JoinInfo_1.JoinType.Meetup ? meetingType : "unknown";
            props.meeting = meeting;
            props.meetingType = meetingType;
        }
        return props;
    }
}
exports.ParsingCompleteEvent = ParsingCompleteEvent;
class LoadingLauncherCompleteEvent {
    constructor(props) {
        this.props = props;
    }
    get name() {
        return "launcher_page_loaded";
    }
    get properties() {
        return this.props;
    }
}
exports.LoadingLauncherCompleteEvent = LoadingLauncherCompleteEvent;
class GenericErrorEvent {
    constructor(props) {
        this.props = props;
    }
    get name() {
        return "launcher_generic_error";
    }
    get properties() {
        return this.props;
    }
}
exports.GenericErrorEvent = GenericErrorEvent;
class ScriptErrorEvent {
    constructor(props) {
        this.props = props;
    }
    get name() {
        return "launcher_script_error";
    }
    get properties() {
        return this.props;
    }
}
exports.ScriptErrorEvent = ScriptErrorEvent;
class BrandingEvent {
    constructor(props) {
        this.props = props;
    }
    get name() {
        return "launcher_branding_event";
    }
    get properties() {
        return this.props;
    }
}
exports.BrandingEvent = BrandingEvent;
class PrivacyStatementEvent {
    constructor(props) {
        this.props = props;
    }
    get name() {
        return "launcher_privacy_statement_event";
    }
    get properties() {
        return this.props;
    }
}
exports.PrivacyStatementEvent = PrivacyStatementEvent;
class UnloadLauncherEvent {
    get name() {
        return "launcher_page_unloaded";
    }
    get properties() {
        return {};
    }
}
exports.UnloadLauncherEvent = UnloadLauncherEvent;
class PDSRedirectEvent {
    get name() {
        return "redirected_to_pds";
    }
    get properties() {
        return {};
    }
}
exports.PDSRedirectEvent = PDSRedirectEvent;
class EcsClientEvent {
    constructor(props) {
        this.props = props;
    }
    get name() {
        return "launcher_ecs_client_event";
    }
    get properties() {
        return this.props;
    }
}
exports.EcsClientEvent = EcsClientEvent;
class EcsClientErrorEvent {
    constructor(props) {
        this.props = props;
    }
    get name() {
        return "launcher_ecs_client_error";
    }
    get properties() {
        return this.props;
    }
}
exports.EcsClientErrorEvent = EcsClientErrorEvent;
class QRCodeGenerationFailedEvent {
    constructor(props) {
        this.props = props;
    }
    get name() {
        return "qr_code_generation_failed";
    }
    get properties() {
        return this.props;
    }
}
exports.QRCodeGenerationFailedEvent = QRCodeGenerationFailedEvent;
class PanelEvent extends AJoinTelemetryEvent {
    constructor() {
        super(...arguments);
        this.panelProps = {
            "Panel.LaunchMethod": "nav",
            "Panel.Region": "main",
            "Panel.Uri": "app.launcher",
            "App.Id": "launcher",
            "App.Name": "JoinLauncher",
        };
    }
}
exports.PanelEvent = PanelEvent;
class PanelViewEvent extends PanelEvent {
    constructor(panelType, joinInfo, props) {
        super(joinInfo);
        this.props = props;
        this.panelViewProps = {
            "Panel.Type": panelType,
        };
    }
    get name() {
        return "panelview";
    }
    get properties() {
        return Object.assign(Object.assign(Object.assign(Object.assign({}, super.getProperties()), this.props), this.panelProps), this.panelViewProps);
    }
}
exports.PanelViewEvent = PanelViewEvent;
class PanelActionEvent extends PanelEvent {
    constructor(scenarioName, moduleName, joinInfo, props) {
        super(joinInfo);
        this.props = props;
        this.panelActionProps = {
            "Action.Gesture": "click",
            "Action.Outcome": "nav",
            "Action.Scenario": scenarioName,
            "Action.ScenarioType": PanelActionScenarioTypes.JoinLauncher,
            "Module.Type": "launcher",
            "Module.Name": moduleName, // e.g.: download, joinOnWeb, joinInApp, redirectToEdge, learnMore
        };
    }
    get name() {
        return "panelaction";
    }
    get properties() {
        return Object.assign(Object.assign(Object.assign(Object.assign({}, super.getProperties()), this.props), this.panelProps), this.panelActionProps);
    }
}
exports.PanelActionEvent = PanelActionEvent;
