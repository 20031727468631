"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileContainer = void 0;
const React = require("react");
const CoreContext_1 = require("../../contexts/CoreContext");
const JoinMobileView_1 = require("./JoinMobileView");
const ModernMobileView_1 = require("./ModernMobileView");
const ModernSimplifiedMobileView_1 = require("./ModernSimplifiedMobileView");
const RootView_1 = require("./RootView");
const SmartiOSBannerService_1 = require("../../services/SmartiOSBannerService");
class MobileContainer extends React.Component {
    constructor() {
        super(...arguments);
        this.onMobileLaunchClick = () => {
            this.markSmartBannerAsSeen();
            this.props.onLaunchClick();
        };
        this.markSmartBannerAsSeen = () => {
            if (this.shouldShowSmartBanner()) {
                SmartiOSBannerService_1.default.markAsSeen();
            }
        };
        this.shouldShowSmartBanner = () => SmartiOSBannerService_1.default.shouldShowSmartBanner() &&
            SmartiOSBannerService_1.default.isIosOnSafari() &&
            this.props.isMobileModernView;
    }
    componentDidMount() {
        if (this.shouldShowSmartBanner()) {
            SmartiOSBannerService_1.default.showSmartBanner(this.props.launchUrl);
        }
    }
    render() {
        const { launchUrl, launchResult, isTflLink, joinInfo } = this.props;
        return (React.createElement(CoreContext_1.CoreContextConsumer, null, ({ featureFlags, isModernView, modernViewType }) => {
            const shouldRenderMobileMeetingModernViewPage = isModernView && modernViewType === RootView_1.ModernViewType.MobileMeeting;
            // Logic for simplified single button join launcher. Supports TFL Links only at the moment
            if (isTflLink) {
                return (React.createElement(ModernSimplifiedMobileView_1.ModernSimplifiedMobileView, { featureFlags: featureFlags, onLaunchClick: this.markSmartBannerAsSeen, launchResult: launchResult, launchUrl: launchUrl, joinInfo: joinInfo }));
            }
            else if (shouldRenderMobileMeetingModernViewPage) {
                return (React.createElement(ModernMobileView_1.ModernMobileView, { featureFlags: featureFlags, onLaunchClick: this.onMobileLaunchClick, launchResult: launchResult, isTflLink: isTflLink, launchUrl: launchUrl }));
            }
            else {
                return React.createElement(JoinMobileView_1.JoinMobileView, { onLaunchClick: this.onMobileLaunchClick });
            }
        }));
    }
}
exports.MobileContainer = MobileContainer;
