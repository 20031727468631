"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBaseLightMeetingsUrl = exports.buildLightMeetingsUrl = void 0;
const UrlHelper_1 = require("./UrlHelper");
const GA_CLOUD_ORIGIN = "https://teams.microsoft.com"; /* GA endpoint */
const LIGHT_MEETINGS_URL_PATH = "/light-meetings/launch";
const buildLightMeetingsUrl = ({ joinInfo, container, buildVersion, deeplinkId, enableLightMeetingsHostnameFromUrl, }) => {
    const url = !!enableLightMeetingsHostnameFromUrl
        ? (0, exports.getBaseLightMeetingsUrl)()
        : new URL("https://teams.microsoft.com/light-meetings/launch");
    url.searchParams.append("agent", "web");
    container && url.searchParams.append("container", container);
    buildVersion && url.searchParams.append("version", buildVersion);
    url.searchParams.append("coords", btoa(JSON.stringify(joinInfo)));
    deeplinkId && url.searchParams.append("deeplinkId", deeplinkId);
    return url.toString();
};
exports.buildLightMeetingsUrl = buildLightMeetingsUrl;
/**
 * Returns the base URL for the light-meetings experience.
 * note: exported only for testing.
 */
const getBaseLightMeetingsUrl = () => {
    // Use the GA hostname for dev host (local environment).
    const urlOrigin = window.location.hostname === "localhost" ? GA_CLOUD_ORIGIN : UrlHelper_1.UrlHelper.getOrigin();
    return new URL(LIGHT_MEETINGS_URL_PATH, urlOrigin);
};
exports.getBaseLightMeetingsUrl = getBaseLightMeetingsUrl;
